import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';

const Layout = dynamic(() => import('common/components/layout'), {
  ssr: false,
});

const Banner = dynamic(() => import('modules/404/components/Banner'), {
  ssr: false,
});

const Inex = () => {
  return (
    <>
      <Head>
        <title>Page not found</title>
      </Head>
      <Layout mainClassName='pb-0'>
        <section className='container pb-[11rem] pt-[4rem] md:pt-[10rem]'>
          <Banner />
        </section>
      </Layout>
    </>
  );
};

export default Inex;
